<style>
  header {
    background-color: white;
    margin: 36px;
  }

  h1 {
    text-align: center;
  }
</style>

<header>
  <h1>민사페이</h1>
</header>
