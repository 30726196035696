<style>
  footer {
    padding: 32px;
    text-align: center;
  }
  .copyright {
    color: #aaa;
    font-size: 14px;
    display: inline-block;
    padding: 20px;
    border-top: 1px solid #ccc;
  }
</style>

<footer>
  <div class="copyright">Copyright 2020 Jungwoong Kim</div>
</footer>
