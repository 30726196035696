<style>
  .isolated {
    border-top: solid 2px lightgrey;
    border-bottom: solid 2px lightgrey;
    padding: 10px;
    margin: 24px;
  }
</style>

<div class="isolated">
  <slot />
</div>
