<style>
  .card {
    border: solid 2px lightgrey;
    border-radius: 12px;
    padding: 24px;
    margin: 20px;
  }
</style>

<div class="card">
  <slot />
</div>
